<template>
  <div class="es-table">
    <slot></slot>
    <EsFiltration :showByOptions="showByOptions" :itemsLength="tableMeta.total" :showBy="30"
                  :filter="filter"
                  :dateFilter="dateFilter" :download="download" :upload="upload" @updatePages="updatePages"
                  :sortable="sortable"
                  @downloadData="downloadData" @uploadData="uploadData" :importData="importData"
                  :importFormats="importFormats" :exportFormats="exportFormats">
      <template slot="sort-menu">
        <slot name="sort-menu"/>
      </template>
    </EsFiltration>
    <v-skeleton-loader
        type="table-thead, table-tbody"
        width="100%"
        height="100%"
        v-if="loading"
    />
    <div id="table-vertical-scrollbar">
      <div id="table-scroll"></div>
    </div>
    <v-data-table
        class="elevation-1 evi-table"
        :headers="headers"
        :items="items"
        hide-default-footer
        v-if="!loading"
        :items-per-page="tableMeta.per_page || showByOptions[1].counter"
    >
      <template v-slot:item.name="{item}">
        <router-link class="es-table__router-link" :to="`${$route.path}/${item.id}`">
          <span>
              {{ item.name }}
          </span>
        </router-link>
      </template>
      <template v-slot:item.user.name="{item}">
        <router-link v-if="item.user" class="es-table__router-link" :to="`/users/${item.id}`">
          <v-avatar
              color="#FFFFFF"
              size="40"
              class="mr-3"
          >
            <img
                :src="item.user.avatar_url ||  require('@/assets/img/users/profile-avatar.png')"
                alt=""
            >
          </v-avatar>
          <span>
              {{ item.user.name }}
          </span>
        </router-link>
      </template>
      <template v-slot:item.client_name="{item}">
        <router-link class="es-table__router-link"
                     :to="item.client_id ? `/clients/${item.client_id}` :`/clients/${item.id}` ">
          <v-avatar
              color="#FFFFFF"
              size="40"
              class="mr-3"
          >
            <img
                :src="item.avatar_url || item.client_avatar_url || require('@/assets/img/users/profile-avatar.png')"
                alt=""
            >
          </v-avatar>
          <div class="es-table__router-link-title">
            {{ item.client_name || item.name }}
            <span class="client-read" v-if="!item.read">новый</span>
          </div>
        </router-link>
      </template>

      <template v-slot:item.last_deal.executor.id="{item}">
        <router-link v-if="item.last_deal" class="es-table__router-link" :to="`/users/${item.last_deal.executor.id}`">
          <v-avatar
              color="#FFFFFF"
              size="40"
              class="mr-3"
          >
            <img
                :src="item.last_deal.executor.avatar_url || require('@/assets/img/users/profile-avatar.png')"
                alt=""
            >
          </v-avatar>
          <span>
              {{ item.last_deal.executor.name }}
          </span>
        </router-link>
      </template>

      <template v-slot:item.id="{item}">
        <router-link class="es-table__router-link" :to="`${$route.path}/${item.id}`">
          <span>
              {{ item.id }}
          </span>
        </router-link>
      </template>

      <template v-slot:item.client.name="{item}">
        <router-link v-if="item.client" class="es-table__router-link" :to="`/clients/${item.client.id}`">
          <v-avatar
              color="#FFFFFF"
              size="40"
              class="mr-3"
          >
            <img
                :src="item.client.avatar_url ||  require('@/assets/img/users/profile-avatar.png')"
                alt=""
            >
          </v-avatar>
          <span>
              {{ item.client.name }}
          </span>
        </router-link>
        <small class="grey--text" v-else>
          Клиент был удален
        </small>
      </template>

      <template v-slot:item.deal_status.name="{item}">
        <div class="es-table__color-status" v-if="item.deal_status"
             :style="{color: `${item.deal_status.color}`}">
          {{ item.deal_status.name }}
        </div>
        <small class="mt-2">
          {{ item.current_status_apply_date | moment("from", "now") }}
        </small>
      </template>
      <template v-slot:item.last_deal.deal_status.name="{item}">
        <div class="es-table__color-status" v-if="item.last_deal && item.last_deal.deal_status"
             :style="{color: `${item.last_deal.deal_status.color}`}">
          {{ item.last_deal.deal_status.name }}
        </div>
        <small v-if="item.last_deal" class="mt-2">
          {{ item.last_deal.current_status_apply_date | moment("from", "now") }}
        </small>
      </template>
      <template v-slot:item.client_status="{item}">
        <div class="es-table__color-status" v-if="item.client_status"
             :style="{color: `${item.client_status.color}`}">
          {{ item.client_status.name }}
        </div>
      </template>

      <template v-slot:item.tags="{item}">
        <div v-if="item.tags.length" class="evi-autocomplete__tag mr-2 mt-2"
             :style="{backgroundColor: `${item.tags[0].color}`, color: `${item.tags[0].text_color}` }">
          {{ item.tags[0].name }}
        </div>
        <v-menu
            top
            offset-y
            open-on-hover
        >
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="evi-autocomplete__tag-more mt-1" v-if="item.tags.length > 1">еще
              {{ item.tags.length - 1 }}
            </div>
          </template>
          <v-list-item class="user-color-status d-flex justify-space-between align-center pa-3">
            <div class="evi-autocomplete__tag mr-2 mt-2" v-for="tag in item.tags" :key="tag.id"
                 :style="{backgroundColor: `${tag.color}`, color: `${tag.text_color}` }">
              {{ tag.name }}
            </div>
          </v-list-item>
        </v-menu>
      </template>

      <template v-slot:item.subdivision.name="{item}">
        <router-link v-if="item.subdivision" class="es-table__router-link" :to="`/subdivisions/${item.subdivision.id}`">
          <span>
              {{ item.subdivision.name }}
          </span>
        </router-link>
      </template>

      <template v-slot:item.last_deal.name="{item}">
        <router-link v-if="item.last_deal" class="es-table__router-link" :to="`/deals/${item.last_deal.id}`">
          <span>
              {{ item.last_deal.name }}
          </span>
        </router-link>
      </template>

      <template v-slot:item.deal.name="{item}">
        <router-link v-if="item.deal" class="es-table__router-link" :to="`/deals/${item.deal.id}`">
          <span>
              {{ item.deal.name }}
          </span>
        </router-link>
      </template>

      <template v-slot:item.payment_deal_name="{item}">
        <router-link v-if="item.name" class="es-table__router-link" :to="`/deals/${item.id}`">
          <span>
              {{ item.name }}
          </span>
        </router-link>
      </template>

      <template v-slot:item.bill_number="{item}">
        <router-link class="es-table__router-link" :to="`/bills/${item.id}`">
          <span>
              {{ item.number }}
          </span>
        </router-link>
      </template>

      <template v-slot:item.conversion="{item}">
        {{ Number.isInteger(item.conversion) ? item.conversion : parseFloat(item.conversion).toFixed(2) }}%
      </template>

      <template v-slot:item.int.name="{item}">
        <router-link v-if="item.int" :title="item.name"
                     class="es-table__router-link" :to="`/integrations/${item.id}`">
          <img width="25" v-if="item.type" class="mr-1" :src="require(`@/assets/img/integrations/${item.type}.svg`)"
               alt=""/>
          <span>
              {{ item.int.name }}
          </span>
        </router-link>
        <router-link v-else-if="item.integration && item.integration.type === 'form'" :title="item.integration.name"
                     class="es-table__router-link" :to="`/integrations/forms/${item.integration.id}`">
          <img width="25" class="mr-1"
               :src="require(`@/assets/img/integrations/form.svg`)" alt=""/>
          <span>
              {{ item.integration.name }}
          </span>
        </router-link>
      </template>

      <template v-slot:item.integration.name="{item}">
        <router-link v-if="item.integration && item.integration.type !== 'form'" :title="item.integration.name"
                     class="es-table__router-link" :to="`/integrations/${item.integration.id}`">
          <img width="25" class="mr-1"
               :src="require(`@/assets/img/integrations/${item.integration.type}.svg`)" alt=""/>
          <span>
              {{ item.integration.name }}
          </span>
        </router-link>
        <router-link v-else-if="item.integration && item.integration.type === 'form'" :title="item.integration.name"
                     class="es-table__router-link" :to="`/integrations/forms/${item.integration.id}`">
          <img width="25" class="mr-1"
               :src="require(`@/assets/img/integrations/form.svg`)" alt=""/>
          <span>
              {{ item.integration.name }}
          </span>
        </router-link>
      </template>

      <template v-slot:item.executor="{item}">
        <router-link v-if="item.executor" class="es-table__router-link" :to="`/users/${item.executor.id}`">
          <v-avatar
              color="#FFFFFF"
              size="40"
              class="mr-3"
          >
            <img
                :src="item.executor.avatar_url || require('@/assets/img/users/profile-avatar.png')"
                alt=""
            >
          </v-avatar>
          <span>
              {{ item.executor.name }}
          </span>
        </router-link>
      </template>

      <template v-slot:item.head="{item}">
        <router-link v-if="item.head" class="es-table__router-link" :to="`/users/${item.head.id}`">
          <v-avatar
              color="#FFFFFF"
              size="40"
              class="mr-3"
          >
            <img
                :src="item.head.avatar_url || require('@/assets/img/users/profile-avatar.png')"
                alt=""
            >
          </v-avatar>
          <span>
              {{ item.head.name }}
          </span>
        </router-link>
      </template>

      <template v-slot:item.users="{item}">
        <div>
          <v-menu
              top
              offset-y
              max-height="156"
              open-on-hover
              v-for="user in item.users"
              :key="user.id"
          >
            <template v-slot:activator="{ on, attrs }">
              <router-link :to="`/users/${user.id}`">
                <v-avatar
                    color="#FFFFFF"
                    size="40"
                    class="user-page__avatar mr-2 mt-1 mb-1"
                    v-bind="attrs" v-on="on"
                >
                  <img
                      :src="user.avatar_url ||require('@/assets/img/users/profile-avatar.png')"
                      alt=""
                  >
                </v-avatar>
              </router-link>
            </template>
            <v-list-item>{{ user.name }}</v-list-item>
          </v-menu>
        </div>
      </template>

      <template v-slot:item.price="{item}">
        {{ item.price | VMask(priceMask) }}
      </template>
      <template v-slot:item.sum="{item}">
        <div v-if="item.sum">
          {{ item.sum | VMask(priceMask) }}
        </div>
      </template>
      <template v-slot:item.sales_amount="{item}">
        {{ item.sales_amount | VMask(priceMask) }}
      </template>
      <template v-slot:item.monthly_plan="{item}">
        {{ item.monthly_plan | VMask(priceMask) }}
      </template>
      <template v-slot:item.average_check="{item}">
        {{ item.average_check | VMask(priceMask) }}
      </template>
      <template v-slot:item.deal.sum="{item}">
        <div v-if="item.deal">
          {{ item.deal.sum | VMask(priceMask) }}
        </div>
      </template>

      <template v-slot:item.is_regular="{item}">
        {{ item.is_regular ? 'Регулярная' : 'Отложенная' }}
      </template>
      <template v-slot:item.notify="{item}">
        {{ item.notify ? 'Включены' : 'Выключены' }}
      </template>

      <template v-slot:item.type="{item}">
        <div v-if="item.type === 'product'">
          Товар
        </div>
        <div v-else-if="item.type === 'service'">
          Услуга
        </div>
      </template>

      <template v-slot:item.monthly_plan_progress="{item}">
        {{ item.monthly_plan_progress }}%
      </template>

      <template v-slot:item.first_contact_date="{item}">
        <div v-if="item.first_contact_date">
          {{ item.first_contact_date | moment('DD.MM.YYYY HH:mm') }}
        </div>
      </template>
      <template v-slot:item.client.first_contact_date="{item}">
        <div v-if="item.client && item.client.first_contact_date">
          {{ item.client.first_contact_date | moment('DD.MM.YYYY HH:mm') }}
        </div>
      </template>
      <template v-slot:item.last_contact_date="{item}">
        <div v-if="item.last_contact_date">
          {{ item.last_contact_date | moment('DD.MM.YYYY в HH:mm') }}
        </div>
      </template>
      <template v-slot:item.client.last_contact_date="{item}">
        <div v-if="item.client && item.client.last_contact_date">
          {{ item.client.last_contact_date | moment('DD.MM.YYYY HH:mm') }}
        </div>
      </template>
      <template v-slot:item.payment_date="{item}">
        <div v-if="item.payment_date">
          {{ item.payment_date | moment('DD.MM.YYYY HH:mm') }}
        </div>
      </template>
      <template v-slot:item.created_at="{item}">
        <div v-if="item.created_at">
          {{ item.created_at | moment('DD.MM.YYYY HH:mm') }}
        </div>
      </template>
      <template v-slot:item.bill_date="{item}">
        <div v-if="item.bill_date">
          {{ item.bill_date | moment('DD.MM.YYYY') }}
        </div>
      </template>
      <template v-slot:item.send_date="{item}">
        <div v-if="item.send_date && !item.is_regular">
          {{ item.send_date | moment('DD.MM.YYYY') }}
        </div>
        <div class="font-italic font-weight-light" v-else>
          Указывается при отложенной рассылке
        </div>
      </template>

      <template v-slot:item.days_of_week="{item}">
        <div v-if="item.days_of_week.length">
          <span v-for="(i, index) in item.days_of_week" :key="i.id">
            {{ dayOfWeek(i) }}<span v-if="index !== item.days_of_week.length -1">,</span>
          </span>
        </div>
        <div class="font-italic font-weight-light" v-else>
          Указывается при регулярной рассылке
        </div>
      </template>

      <template v-slot:item.first_message_answer_time="{item}">
        <div v-if="item.first_message_answer_time">
          {{ returnTimeAnswer(item.first_message_answer_time) }}
        </div>
      </template>
      <template v-slot:item.client.first_message_answer_time="{item}">
        <div v-if="item.client && item.client.first_message_answer_time">
          {{ returnTimeAnswer(item.client.first_message_answer_time) }}
        </div>
      </template>

      <template v-slot:item.last_comment.comment="{item}">
        <div v-if="item.last_comment" class="last-comment" :title="item.last_comment.comment">
          {{ item.last_comment.comment }}
        </div>
      </template>

      <template v-slot:item.tax="{item}">
        {{ returnNds(item.tax).name }}
      </template>

      <template v-slot:item.activity_time="{item}">
        <div class="es-table__color-status">
          {{ activityTime(item) }}
        </div>
      </template>

      <template v-slot:item.url="{item}">
        <a class="es-table__linked" :href="item.url" target="_blank">
          Открыть PDF
        </a>
      </template>

    </v-data-table>
  </div>
</template>

<script>
import EsFiltration from "./EsFiltration";
import {TimeAnswerMixin} from "@/mixins/TimeAnswerMixin";
import {OpenPagesMixin} from "@/mixins/OpenPagesMixin";
import {Weekdays} from "@/mixins/Weekdays";

import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import {mapState} from "vuex";
import {pluralize} from "../../../tools/Helpers";

const priceMask = createNumberMask({
  prefix: '',
  allowDecimal: true,
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ' ',
  allowNegative: true,
});

export default {
  name: "EsTable",
  components: {EsFiltration},
  mixins: [TimeAnswerMixin, OpenPagesMixin, Weekdays],
  props: {
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: false,
    },
    dateFilter: {
      type: Boolean,
      default: false,
    },
    download: {
      type: String,
      required: false,
    },
    upload: {
      type: String,
      required: false,
    },
    sortable: {
      type: Boolean,
      default: false,
    },
    filter: {
      type: Object,
    },
    importData: {
      type: Object,
    },
    importFormats: {
      type: Array,
    },
    exportFormats: {
      type: Array,
    },
  },
  data: () => ({
    priceMask,
    showByOptions: [
      {
        text: '5',
        counter: 5,
      },
      {
        text: '10',
        counter: 10,
      },
      {
        text: '15',
        counter: 15,
      },
      {
        text: '30',
        counter: 30,
      },
      {
        text: '50',
        counter: 50,
      },
      {
        text: '100',
        counter: 100,
      },
    ],
  }),
  computed: {
    ...mapState("common", ["loading", "tableMeta"]),
    ...mapState("bills", ["ndsItems"]),
  },
  methods: {
    dayOfWeek(number) {
      return this.daysOfWeek.find(i => i.id.toString() === number.toString()).name;
    },
    updatePages(payload) {
      this.$emit('update', payload);
    },
    downloadData(payload) {
      this.$emit('downloadData', payload);
    },
    uploadData(payload) {
      this.$emit('uploadData', payload);
    },
    activityTime(user) {
      return this.returnTimeUserActivity(user.activity_time);
    },
    returnTimeUserActivity(time) {
      let hours = Math.floor(time / 60);
      let minutes = Math.floor((time / 60 - hours) * 60);

      let minutesPluralize = ['минута', 'минуты', 'минут'];
      let hoursPluralize = ['час', 'часа', 'часов'];

      if (time > 60) {
        return pluralize(hours, hoursPluralize) + ' ' + pluralize(minutes, minutesPluralize);
      } else {
        return pluralize(time, minutesPluralize);
      }
    },
    returnNds(id) {
      return this.ndsItems.find((n) => n.id === id);
    },
    scrollWatcher() {
      let element = document.getElementById("table-scroll");
      let scrollbar = document.getElementById("table-vertical-scrollbar");
      let table = document.querySelector('.v-data-table__wrapper');

      if (table && element && scrollbar) {
        element.style.width = table.scrollWidth + 'px';
        scrollbar.style.width = table.offsetWidth + 6 + 'px';

        table.addEventListener('scroll', function () {
          scrollbar.scrollLeft = table.scrollLeft;
        });
        scrollbar.addEventListener('scroll', function () {
          table.scrollLeft = scrollbar.scrollLeft;
        });
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.scrollWatcher();
    }, 1000)
  },
  watch: {
    items: {
      handler() {
        this.scrollWatcher();
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.es-table {
  background: #FFFFFF;
  //box-shadow: 10px -2px 54px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding: 60px 25px;
  margin-bottom: 25px;

  h3 {
    font-weight: bold;
    font-size: 20px;
    margin-left: 23px;
  }

  .evi-table {
    border-radius: 10px;
    border: 1px solid #DDDFE1;
    box-shadow: none !important;

    .v-data-table__wrapper > table > thead > tr:last-child > th {
      padding: 21px 25px !important;
    }
  }

  .evi-pagination {
    position: relative;
  }

  &__linked {
    color: $evi-main-color;
    cursor: pointer;
    display: inline;
    position: relative;
    transition: 0.2s;
    text-decoration: none;

    &.bill {
      display: inline-block;
      width: 100%;
      height: 100%;

      .title-line {
        display: none !important;
      }
    }

    .title-line {
      display: block;
      height: 1px;
      width: 100%;
      position: absolute;
      background: $evi-main-color;
      opacity: 0;
      transition: 0.2s;
      left: 3px;
      bottom: -4px;
    }

    &:hover {
      .title-line {
        opacity: 1;
      }
    }
  }

  &__router-link {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    text-decoration: none;

    &-title {
      width: 100%;
    }

    span, &-title {
      color: $evi-main-color;
      cursor: pointer;
      transition: 0.2s;
      position: relative;
      display: block;
      text-decoration: none;
      font-size: 12px;
      white-space: nowrap;

      &:hover {
        text-decoration: underline;
      }

      .client-read {
        display: block;
        position: absolute;
        color: rgb(255, 101, 83) !important;
        font-size: 9px;
        border-radius: 100%;
        right: -5px;
        top: -15px;
        text-decoration: none;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  &__title {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;

    span {
      color: $evi-main-color;
      cursor: pointer;
      transition: 0.2s;
      position: relative;
      display: block;

      .title-line {
        display: block;
        height: 1px;
        width: 100%;
        position: absolute;
        background: $evi-main-color;
        opacity: 0;
      }

      &:hover {
        .title-line {
          opacity: 1;
        }
      }
    }

  }

  .user-page__avatar {
    cursor: pointer;
  }

  .last-comment {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.integration-name {
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
}

#table-scroll {
  width: 50px;
  height: 12px;
  background: transparent;
}

#table-vertical-scrollbar {
  width: 50px;
  height: 10px;
  position: absolute;
  overflow-x: auto;
  border-radius: 50%;
  background: transparent;
}
</style>

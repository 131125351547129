<template>
  <div v-if="isDesktop">
    <v-app-bar
        absolute
        color="transparent"
        class="evi-toolbar"
    >
      <v-toolbar-title class="evi-toolbar__title">
        <div class="d-flex">
          <h2 class="mr-2">{{ currentRouteTitle }}</h2>
          <img v-if="hasSettings" @click="openSettings()"
               class="pointer" :src="require('@/assets/img/common/settings.svg')" alt="">
        </div>
        <p>{{ new Date() | moment("dddd, D MMMM YYYY") }}</p>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <div class="mr-8 evi-clock">
        {{ timestamp }}
      </div>

      <div class="evi-toolbar__night"
           :class="{'active': $vuetify.theme.dark}">
        <img :src="require('@/assets/img/menu/moon.svg')" alt="">
        <v-switch
            :label="`Темная тема`"
            v-model="$vuetify.theme.dark"
            @click="toggleDarkMode"
        ></v-switch>
      </div>

      <v-btn icon class="mr-4">
        <NotificationsDialog :isDark="$vuetify.theme.dark" :count="count" :notifications="notifications"
                             @update="$emit('getNotificationsData')"/>
      </v-btn>

      <v-menu offset-y open-on-hover>
        <template v-slot:activator="{ on, attrs }">
          <div class="evi-toolbar__profile" v-on="on" v-bind="attrs">
            <v-avatar
                color="#FFFFFF"
                size="46"
                class="mr-4"
            >
              <img
                  :src="profile.avatar_url ||require('@/assets/img/users/profile-avatar.png')"
                  alt=""
              >
            </v-avatar>
            <span>{{ profile.name }}</span>
          </div>
        </template>
        <v-list dense class="profile-menu">
          <v-list-item-group>
            <v-list-item @click="$router.push({path: '/profile'})">
              <v-list-item-icon class="evi-sidebar__icon">
                <img width="16" :src="require(`@/assets/img/profile/profile.svg`)" alt=""/>
              </v-list-item-icon>
              <v-list-item-title>Профиль</v-list-item-title>
            </v-list-item>
            <v-list-item @click="logOut">
              <v-list-item-icon class="evi-sidebar__icon">
                <img width="16" :src="require(`@/assets/img/profile/exit.svg`)" alt=""/>
              </v-list-item-icon>
              <v-list-item-title>Выйти</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer permanent fixed class="evi-sidebar" width="256" floating>
      <v-list-item class="mt-5">
        <img width="46" class="mr-2 pointer" @click="toEviSales" :src="require('@/assets/logo.svg')" alt="">
        <v-list-item-content>
          <v-list-item-title class="evi-sidebar__logo-title pointer" @click="toEviSales">
            EviSales CRM
          </v-list-item-title>
          <v-list-item-subtitle class="evi-sidebar__company" :title="tenant.company_name">
            {{ tenant.company_name }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list class="mt-5" v-if="menuLoading">
        <v-skeleton-loader
            type="list-item, list-item, list-item, list-item"
            width="100%"
            height="100%"
        />
      </v-list>
      <v-list v-if="menuLoading">
        <v-skeleton-loader
            type="list-item, list-item, list-item, list-item"
            width="100%"
            height="100%"
        />
      </v-list>
      <v-list v-if="menuLoading">
        <v-skeleton-loader
            type="list-item, list-item, list-item, list-item"
            width="100%"
            height="100%"
        />
      </v-list>

      <div v-else-if="!menuLoading">
        <v-list
            nav
            class="mt-5"
        >
          <v-list-item
              v-for="item in menu.common"
              :key="item.id"
              link
              :to="`/${item.route}`"
              class="evi-sidebar__item"
          >
            <v-list-item-icon class="evi-sidebar__icon">
              <img width="18" :src="require(`@/assets/img/menu/${item.route}.svg`)" alt=""/>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="evi-sidebar__title" v-if="item.route === 'chat' && chatsUnreadCount">
                {{ item.title }}
                <v-badge
                    color="#FF6553"
                    :content="chatsUnreadCount"
                    :value="chatsUnreadCount"
                    class="ml-2"
                    v-if="chatsUnreadCount > 0"

                >
                </v-badge>
              </v-list-item-title>
              <v-list-item-title v-else class="evi-sidebar__title d-flex justify-space-between">
                {{ item.title }}
                <v-menu
                    top
                    offset-y
                    open-on-hover
                    max-height="156"
                    v-if="item.isBeta"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <span class="beta-tag" v-bind="attrs" v-on="on">БЕТА</span>
                  </template>
                  <v-list-item class="user-color-status d-flex justify-space-between align-center pa-3">
                    Функционал находится на этапе бета-тестирования, но уже доступен к работе.
                    <br>
                    При возникновении проблем в работе, можете написать на почту support@evisales.com
                    <br>
                    или через форму в разделе "Обратная связь".
                  </v-list-item>
                </v-menu>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider v-if="menu.company && menu.company.length" class="evi-sidebar__divider"></v-divider>

        <v-list
            nav
        >
          <v-list-item
              v-for="item in menu.company"
              :key="item.id"
              link
              :to="`/${item.route}`"
              class="evi-sidebar__item"
          >
            <v-list-item-icon class="evi-sidebar__icon">
              <img width="18" :src="require(`@/assets/img/menu/${item.route}.svg`)" alt=""/>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="evi-sidebar__title">{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider v-if="menu.settings && menu.settings.length" class="evi-sidebar__divider"></v-divider>

        <v-list
            nav
        >
          <v-list-item
              v-for="item in menu.settings"
              :key="item.id"
              link
              :to="`/${item.route}`"
              class="evi-sidebar__item"
          >
            <v-list-item-icon class="evi-sidebar__icon">
              <img :src="require(`@/assets/img/menu/${item.route}.svg`)" alt=""/>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="evi-sidebar__title">{{ item.title }}</v-list-item-title>
            </v-list-item-content>

            <v-list-group
                no-action
                sub-group
                v-if="item.items"
            >
              <v-list-item
                  v-for="child in item.items"
                  :key="child.title"
              >
                <v-list-item-content>
                  <v-list-item-title class="evi-sidebar__title"> {{ child.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>

          </v-list-item>
        </v-list>

        <v-divider class="evi-sidebar__divider"></v-divider>

        <v-list
            nav
        >
          <v-list-item
              v-for="item in menu.contact"
              :key="item.id"
              link
              :to="`/${item.route}`"
              class="evi-sidebar__item"
          >
            <v-list-item-icon class="evi-sidebar__icon">
              <img :src="require(`@/assets/img/menu/${item.route}.svg`)" alt=""/>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="evi-sidebar__title">{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </v-navigation-drawer>
    <v-dialog
        v-model="settingsModal"
        max-width="612px"
    >
      <component :is="modalSettingsComponent" @close="settingsModal = false"/>
    </v-dialog>

    <!--content goes here -->
    <v-main class="evi-main">
      <slot/>
    </v-main>

    <!--content goes here -->
  </div>
  <div class="mobile-none" v-else>
    <img width="69" class="mb-5" :src="require('@/assets/logo.svg')" alt="">
    На данный момент EviSales CRM
    <br>
    не адаптирована на мобильные и планшетные
    <br>
    устройства :(
    <br>
    <br>
    Вы можете отобразить десктопную (ПК) версию
    <br>
    сайта на устройствах в настройках вашего браузера
    <br>
    <br>
    <strong>Мы обязательно добавим мобильное приложение!</strong>
    <img width="286" :src="require('@/assets/img/not-found/mobile.svg')"/>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import NotificationsDialog from "../components/notifications/NotificationsDialog";
import {hasRight, hasRightInclude} from '@/utils/access/hasRight';
import IntegrationSettingsModal from "@/components/settings/IntegrationSettingsModal";
import {eventBus} from "@/main";
import DealSettingsModal from "@/components/settings/DealSettingsModal";
import ChatSettingsModal from "@/components/settings/ChatSettingsModal";
import MailSettingsModal from "@/components/settings/MailSettingsModal.vue";

export default {
  name: "MainLayout",
  components: {MailSettingsModal, ChatSettingsModal, DealSettingsModal, IntegrationSettingsModal, NotificationsDialog},
  data: () => ({
    currentRouteTitle: '',
    timestamp: '',
    nigthTheme: false,
    profileMenu: false,
    menuLoading: false,
    settingsModal: false,
  }),
  computed: {
    ...mapState("common", ["menu"]),
    ...mapState("chat", ["chatsUnreadCount"]),
    ...mapState("payments", ["paymentInfo"]),
    ...mapState("user", ["profile", "tenant"]),
    ...mapState("notifications", ["notifications", "count"]),
    currentRoute() {
      return this.$route.path;
    },
    isDesktop() {
      return window.innerWidth >= 1000;
    },
    hasSettings () {
      return this.$route.meta.hasLocalSettings || (this.$route.meta.settings && this.hasRight(this.$route.meta.settings));
    },
    modalSettingsComponent () {
      switch (this.$route.meta.settings) {
        case 'integrations.settings':
          return IntegrationSettingsModal;
        case 'deals.settings':
          return DealSettingsModal;
        case 'chat.settings':
          return ChatSettingsModal;
        case 'mail.settings':
          return MailSettingsModal;
        default:
          return MailSettingsModal;
      }
    }
  },
  methods: {
    ...mapActions({
      setMenu: 'common/setMenu',
      logOut: 'user/logOut',
      getProfile: 'user/getProfileWithoutLoading',
      getPaymentsInfo: 'payments/getPaymentsInfo',
      getAllScripts: 'scripts/getAllScripts',
      getChatsUnreadCount: 'chat/getChatsUnreadCount',
      getIntegrationSettings: 'integrations/getIntegrationsSettings',
    }),
    hasRight: hasRight,
    openSettings() {
      this.settingsModal = true;
    },
    changeCurrentRouteTitle() {
      this.currentRouteTitle = this.$route.meta.title;
    },
    toggleDarkMode() {
      eventBus.$emit('toggleDarkTheme', this.$vuetify.theme.dark.toString());
      localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    },
    toEviSales() {
      window.open('https://evisales.com', '_blank');
    },
    getNow() {
      this.timestamp = this.$moment().format('HH:mm');
    },
  },
  created() {
    setInterval(this.getNow, 1000);
  },
  mounted() {
    this.menuLoading = true;
    this.getProfile().finally(() => {
      this.setMenu().finally(() => this.menuLoading = false);
    });
    this.getIntegrationSettings();
    this.getChatsUnreadCount();

    if (hasRightInclude('payment.access')) {
      this.getPaymentsInfo().then((r) => {
        if (r && !r.is_active) {
          this.$router.push({name: 'Payments'}).catch(() => {
          });
        }
      });
    }

    this.$emit('getNotificationsData');

    this.$nextTick(() => {
      this.changeCurrentRouteTitle();
    });

    const theme = localStorage.getItem("dark_theme");
    if (theme) {
      if (theme === "true") {
        this.$vuetify.theme.dark = true;
      } else {
        this.$vuetify.theme.dark = false;
      }
    } else if (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      this.$vuetify.theme.dark = true;
      localStorage.setItem(
          "dark_theme",
          this.$vuetify.theme.dark.toString()
      );
    }
  },
  watch: {
    currentRoute() {
      this.changeCurrentRouteTitle();
      // if (this.paymentInfo && !this.paymentInfo.is_active && !newVal.includes('feedback'))
      if (this.paymentInfo && !this.paymentInfo.is_active)
        this.$router.push({name: 'Payments'}).catch(() => ({}));
    },
    '$vuetify.theme.dark': {
      handler: function (val) {
        if (val) {
          this.$emit('darkScroll');
        } else {
          this.$emit('lightScroll');
        }
      },
      deep: true,
      immediate: true
    },
    paymentInfo(val) {
      if (val.is_active) {
        this.getAllScripts();
      }
    }
  },
}
</script>

<template>
  <div class="es-table__header mb-5">
    <div class="es-table__filtration mb-2">
      <div id="pagination-component" class="es-table__showby d-flex align-start" v-if="!noOptions">
        <span class="mr-3">Показать</span>
        <v-select
            class="select-count"
            color="#757D8A"
            v-model="localShowBy"
            outlined
            dense
            :items="showByOptions"
            item-value="counter"
            append-icon="$expand"
            item-text="text"
            item-color="green"
            hide-details
        />
        <span class="ml-3">записей</span>
      </div>
      <div class="d-flex" :class="{'w-100': noOptions, 'justify-space-between' : noOptions}">
        <div class="d-flex evi-table-search">
          <v-text-field
              label="Поиск"
              class="evi-text-field mr-5"
              color="#44D370"
              v-model="search"
              clearable
              hide-details
          >
            <template v-slot:append>
              <img class="mt-1" width="15" :src="require('@/assets/img/analytics/search.svg')" alt="">
            </template>
          </v-text-field>
          <EsDatePickerRange v-if="dateFilter" :dateFrom="dateFrom" :dateTo="dateTo" @pickDates="pickDates"
                             @clearDates="clearDates"/>
          <EsSort v-if="sortable" :filter="filter">
            <template slot="sort-menu">
              <slot name="sort-menu"/>
            </template>
          </EsSort>
        </div>
        <div class="evi-pagination d-flex justify-space-between">
          <v-pagination
              v-model="page"
              :length="pagesLength"
              :total-visible="4"
              color="#725DFF"
              class="evi-pagination__pages"
              @input="updatePages"
          />
          <div class="excel-buttons">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="evi-button-green export mr-2" v-if="hasRight(upload)" v-on="on" :bind="attrs">
                  <img class="mr-2" width="23" :src="require('@/assets/img/common/import.svg')" alt="">
                  Импорт
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item-group>
                  <v-list-item
                      class="pointer"
                      @click="importModal = true"
                  >
                    <v-list-item-icon class="evi-sidebar__icon">
                      <img width="16" :src="require(`@/assets/img/common/import.svg`)" alt=""/>
                    </v-list-item-icon>
                    <v-list-item-title class="v-list-item__title">Импортировать по шаблону EviSales CRM</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                      v-for="(format, index) in exportFormats"
                      :key="index"
                      class="pointer"
                      @click="uploadDataFrom(format)"
                  >
                    <v-list-item-icon class="evi-sidebar__icon">
                      <img width="16" :src="require(`@/assets/img/common/import.svg`)" alt=""/>
                    </v-list-item-icon>
                    <v-list-item-title class="v-list-item__title">Импортировать из {{ format }}</v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="evi-button-green export mr-2" v-if="hasRight(download)" v-on="on" :bind="attrs">
                  <img class="mr-2" width="23" :src="require('@/assets/img/common/export.svg')" alt="">
                  Экспорт
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item-group>
                  <v-list-item
                      v-for="(format, index) in importFormats"
                      :key="index"
                      class="pointer"
                      @click="downloadData(format)"
                  >
                    <v-list-item-icon class="evi-sidebar__icon">
                      <img width="16" :src="require(`@/assets/img/analytics/excel.svg`)" alt=""/>
                    </v-list-item-icon>
                    <v-list-item-title class="v-list-item__title">Экспортировать .{{ format }}</v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </div>
        </div>
      </div>
    </div>
    <span class="mr-8">Показано с {{ firstShowBy }} по {{ maxShowBy }} из {{ itemsLength }} записей</span>
    <v-dialog v-model="importModal" width="700">
      <ImportModal :data="importData" @uploadData="uploadData" @close="importModal = false"></ImportModal>
    </v-dialog>
    <v-dialog v-model="importFromModal" width="700">
      <ImportFromModal :data="importData" :fromTitle="selectedImportFrom" @uploadData="uploadData"
                       @close="importFromModal = false"></ImportFromModal>
    </v-dialog>
  </div>
</template>

<script>
import EsDatePickerRange from "./EsDatePickerRange";
import EsSort from "./EsSort";
import {mapState} from "vuex";
import {eventBus} from "../../../main";
import ImportModal from "./ImportModal";
import {hasRight} from '@/utils/access/hasRight';
import ImportFromModal from "@/components/сommon/EsTable/ImportFromModal";

export default {
  name: "EsFiltration",
  components: {ImportFromModal, ImportModal, EsSort, EsDatePickerRange},
  props: {
    noOptions: {
      type: Boolean,
      default: false,
    },
    showByOptions: {
      type: Array,
      required: true,
    },
    itemsLength: {
      type: Number,
      default: 0,
    },
    showBy: {
      type: Number,
      required: true,
    },
    dateFilter: {
      type: Boolean,
      default: false,
    },
    sortable: {
      type: Boolean,
      default: false,
    },
    filter: {
      type: Object,
    },
    importData: {
      type: Object,
    },
    upload: {
      type: String,
      required: false,
    },
    download: {
      type: String,
      required: false,
    },
    importFormats: {
      type: Array,
    },
    exportFormats: {
      type: Array,
    },
  },
  data: () => ({
    page: 1,
    localShowBy: 0,
    search: '',
    timerId: null,
    importModal: false,
    importFromModal: false,
    dateFrom: '',
    dateTo: '',
    selectedImportFrom: '',
    searchFilters: ['search', 'filter[number]', 'filter[subject.name]', 'filter[name]'],
  }),
  computed: {
    pagesLength() {
      if (!this.localShowBy)
        return Math.ceil(this.itemsLength / this.showBy);
      return Math.ceil(this.itemsLength / this.localShowBy);
    },
    maxShowBy() {
      if (this.localShowBy + this.localShowBy * (this.page - 1) > this.itemsLength) {
        return this.itemsLength;
      }
      return this.localShowBy + this.localShowBy * (this.page - 1);
    },
    firstShowBy() {
      if (this.itemsLength > 0) {
        return 1 + this.localShowBy * (this.page - 1);
      }
      return 0;
    },
    ...mapState("common", ["tableMeta"]),
    ...mapState("analytics", ["userId"]),
    getArgs() {
      const args = {
        per_page: this.localShowBy,
        page: this.page,
      };

      const setSearch = (value) => {
        if (this.$route.fullPath.includes('analytics')) {
          args['search'] = value;
        } else if (this.$route.fullPath.includes('bills')) {
          args['filter[number]'] = value;
        } else if (this.$route.fullPath.includes('event-log')) {
          args['filter[subject.name]'] = value;
        } else {
          args['filter[name]'] = value;
        }
      }

      if (this.$route.fullPath.includes('users') && this.$route.query['filter[is_active]'] === undefined) {
        args['filter[is_active]'] = true;
      }

      const setDatesFilter = (to, from) => {
        args['date_to'] = to;
        args['date_from'] = from;
      }

      if (this.search) {
        setSearch(this.search);
      } else {
        setSearch(undefined);
      }

      if (this.dateFilter && this.dateTo && this.dateFrom) {
        setDatesFilter(this.dateTo, this.dateFrom);
      } else {
        setDatesFilter();
      }

      if (this.userId) {
        args['user_id'] = this.userId;
      }

      for (const key in this.$route.query) {
        if (
            this.$route.query[key]
            && !(key in args)
        ) {
          args[key] = this.$route.query[key];
        }
      }

      for (const key in this.filter) {
        if (this.filter[key] !== undefined) {
          args[key] = this.filter[key];
        }
      }

      for (const key in args) {
        if (!args[key] && typeof args[key] !== 'boolean') {
          delete args[key];
        }
      }

      return args;
    },
  },
  methods: {
    hasRight: hasRight,
    pickDates(from, to) {
      this.dateFrom = from;
      this.dateTo = to;

      this.updatePages();
    },
    clearDates() {
      this.dateFrom = '';
      this.dateTo = '';
    },
    updatePages() {
      this.$emit('updatePages', this.getArgs);
      //роуты с пагинацией
      this.$router.replace({
        query: this.getArgs,
      }).catch(() => {
      });
    },
    initData() {
      //инициализация фильтров
      this.localShowBy = parseInt(this.$route.query.per_page) || this.showBy;
      this.page = parseInt(this.$route.query.page) || 1;

      for (const key in this.$route.query) {
        if (this.searchFilters.some(i => i === key)) {
          this.search = this.$route.query[key] || '';
        }
      }
      this.dateFrom = this.$route.query['date_from'] || '';
      this.dateTo = this.$route.query['date_to'] || '';

      this.$emit('updatePages', this.getArgs);
      this.$router.replace({
        query: this.getArgs,
      }).catch(() => {
      });
    },
    downloadData(exportType) {
      let args = {
        export: exportType ? exportType : true,
        per_page: this.localShowBy,
        page: this.page,
      };
      if (this.search) {
        args['search'] = this.search;
      }
      if (this.dateTo && this.dateFrom) {
        args['date_from'] = this.dateFrom;
        args['date_to'] = this.dateTo;
      }

      for (const key in this.filter) {
        if (this.filter[key] !== undefined) {
          args[key] = this.filter[key];
        }
      }

      this.$emit('downloadData', args);
    },
    uploadData(payload) {
      this.$emit('uploadData', payload);
    },
    uploadDataFrom(from) {
      this.selectedImportFrom = from;
      this.importFromModal = true;
    }
  },
  mounted() {
    this.localShowBy = this.showBy;
    this.initData();

    //слушаем сброс пагинации
    eventBus.$on('cancelPagination', () => {
      this.localShowBy = this.showBy;
      this.page = 1;
    });
  },
  watch: {
    localShowBy(newV, oldV) {
      if (parseInt(this.$route.query.per_page) !== newV) this.page = 1;
      if (oldV !== 0)
        this.updatePages();
    },
    'filter': {
      handler(val) {
        const newFilter = {...val};
        const oldFilter = {};

        for (const key in newFilter) {
          if (Object.prototype.hasOwnProperty.call(newFilter, key)) {
            oldFilter[key] = this.$route.query[key];
          }
        }

        for (const key in newFilter) {
          if (newFilter[key]) {
            if (!isNaN(newFilter[key])) {
              newFilter[key] = newFilter[key].toString();
            } else if (Array.isArray(newFilter[key])) {
              newFilter[key] = newFilter[key].map(i => i.toString());
            }
          }
        }

        if (JSON.stringify(oldFilter) !== JSON.stringify(newFilter)) {

          // console.warn(JSON.stringify(oldFilter))
          // console.warn(JSON.stringify(newFilter))

          this.page = 1;
          this.updatePages();
        }
      },
      deep: true,
    },
    search(v) {
      const key = this.searchFilters.find((k) => this.$route.query[k]);
      // console.warn(key)
      if (
          v !== this.$route.query[key]
      ) {
        if (this.timerId)
          clearTimeout(this.timerId);

        this.timerId = setTimeout(() => {
          this.page = 1;
          this.updatePages();
        }, 500);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.es-table {
  &__header {
    padding: 0 23px;
    margin-top: 32px;

    span {
      font-weight: normal;
      font-size: 14px;
      color: #ABAFB3;
    }
  }

  &__filtration {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    //max-width: 1062px;

    .evi-text-field {
      max-width: 263px;
      margin-top: -7px;
    }

    .evi-table-search {
      margin-right: 30px;
    }
  }

  &__showby {
    span {
      color: #ABAFB3;
      margin-top: 10px;
    }
  }
}


.evi-pagination {
  position: relative;
}

.evi-button-green.download {
  position: absolute;
  top: -55px;
  right: 10px;
}

.select-count {
  max-width: 100px;
}

.excel-buttons {
  position: absolute;
  top: -59px;
  right: 10px;
  display: flex;
}
</style>

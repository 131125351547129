var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"modal-create settings"},[_c('v-form',{ref:"form"},[_c('v-card-title',{staticClass:"d-flex justify-center"},[_c('span',{staticClass:"text-h5 text-center mb-11"},[_vm._v("Общие настройки интеграций")])]),_c('v-card-text',{staticClass:"modal-create__form"},[_c('div',{staticClass:"close-dialog",on:{"click":_vm.closeDialog}},[_c('img',{attrs:{"src":require('@/assets/img/common/close-dialog.svg')}})]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-radio-group',{staticClass:"evi-radio-button-group",scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Распределение клиентов "),_c('v-menu',{attrs:{"top":"","offset-y":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('strong',_vm._g(_vm._b({},'strong',attrs,false),on),[_c('v-icon',{attrs:{"size":"18","color":"#289E80"}},[_vm._v("mdi-information-outline")])],1)]}}])},[_c('v-list-item',{staticClass:"user-color-status d-flex justify-space-between align-center pa-3"},[_vm._v(" По умолчанию, сотрудник сам выбирает клиента из всех написавших "),_c('br'),_vm._v(" Как только сотрудник отправляет первое сообщение клиенту, "),_c('br'),_vm._v(" он закрепляется за ним. "),_c('br'),_c('br'),_vm._v(" Если выбрать автоматическое распределение, то можно будет "),_c('br'),_vm._v(" задать теги для клиентов, которые будут поочередно распределяться на них. "),_c('br'),_vm._v(" Ответственный сотрудник увидит свой тег и заберет клиента, написав ему. ")])],1)]},proxy:true}]),model:{value:(_vm.integrationsClientsDistribution),callback:function ($$v) {_vm.integrationsClientsDistribution=$$v},expression:"integrationsClientsDistribution"}},[_c('div',{staticClass:"d-flex align-center"},_vm._l((_vm.clientsDistributionTypes),function(item){return _c('v-radio',{key:item.value,staticClass:"mb-0 mr-6 evi-radio",attrs:{"label":item.name,"value":item.value,"color":"#45BF6A"}})}),1)])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-radio-group',{staticClass:"evi-radio-button-group",scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Видимость диалогов и напоминаний "),_c('v-menu',{attrs:{"top":"","offset-y":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('strong',_vm._g(_vm._b({},'strong',attrs,false),on),[_c('v-icon',{attrs:{"size":"18","color":"#289E80"}},[_vm._v("mdi-information-outline")])],1)]}}])},[_c('v-list-item',{staticClass:"user-color-status d-flex justify-space-between align-center pa-3"},[_vm._v(" По умолчанию, сотрудники видят всех написавших клиентов. "),_c('br'),_vm._v(" Когда за каким-то клиентом закрепляется сотрудник, то клиент пропадает "),_c('br'),_vm._v(" из поля зрения остальных сотрудников. "),_c('br'),_c('br'),_vm._v(" Выбирая видимость всех диалогов, сотрудник сможет видеть "),_c('br'),_vm._v(" все диалоги с клиентами и напоминания. "),_c('br'),_vm._v(" Это полезно, если сотруднику нужно помочь коллеге в диалоге с клиентом. ")])],1)]},proxy:true}]),model:{value:(_vm.integrationsUsersViewAllChats),callback:function ($$v) {_vm.integrationsUsersViewAllChats=$$v},expression:"integrationsUsersViewAllChats"}},[_c('div',{staticClass:"d-flex align-center"},_vm._l((_vm.usersViewChatTypes),function(item){return _c('v-radio',{key:item.value,staticClass:"mb-0 mr-6 evi-radio",attrs:{"label":item.name,"value":item.value,"color":"#45BF6A"}})}),1)])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-radio-group',{staticClass:"evi-radio-button-group",scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Оповещения о новых клиентах с форм обратной связи "),_c('v-menu',{attrs:{"top":"","offset-y":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('strong',_vm._g(_vm._b({},'strong',attrs,false),on),[_c('v-icon',{attrs:{"size":"18","color":"#289E80"}},[_vm._v("mdi-information-outline")])],1)]}}])},[_c('v-list-item',{staticClass:"user-color-status d-flex justify-space-between align-center pa-3"},[_vm._v(" Настройка регулирует получения уведомлений о новом клиенте при поступлении "),_c('br'),_vm._v(" обращения с формы обратной связи, которая интегрирована с EviSales CRM. ")])],1)]},proxy:true}]),model:{value:(_vm.notificationsFromForm),callback:function ($$v) {_vm.notificationsFromForm=$$v},expression:"notificationsFromForm"}},[_c('div',{staticClass:"d-flex align-center"},_vm._l((_vm.newClientsFormNotifications),function(item){return _c('v-radio',{key:item.value,staticClass:"mb-0 mr-6 evi-radio",attrs:{"label":item.name,"value":item.value,"color":"#45BF6A"}})}),1)])],1)],1),_c('v-card-actions',{staticClass:"modal-create__actions"},[_c('v-btn',{staticClass:"evi-button-green evi-button-green--fill",on:{"click":_vm.save}},[_vm._v(" Сохранить ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }